export const Column = [
    // {
    //     prop: "name1",
    //     label: "项目编号",
    //     width: 140,
    // },
    {
        prop: "consultDesc",
        label: "远程诊断提出的问题",
    },

    {
        prop: "consultTitle",
        label: "作物类型",
        width: 160,
    },
    {
        prop: "createTime",
        label: "上传时间",
        width: 160,
    },
    {
        prop: "name5",
        label: "附件（照片）",
        width: 150,
        // template: true,
    },
    {
        prop: "isReply",
        label: "解答状态",
        width: 90,
        template: true,
    },
]
