<template>
  <div class="wytg">
    <div class="form-list">
      <div class="table-search">
        <h3>远程诊断记录</h3>
        <!-- 方便找页面 后期可能删除 -->
        <FormQuery
          ref="formquery"
          :data="Query"
          :key="keyForm"
          @submit="search"
          @reset="reset"
        >
        </FormQuery>
      </div>
      <div class="table-content">
        <table-page
          :data="tableData"
          :columns="columns"
          :currentPage="query.current"
          :pageSize="query.size"
          :total="total"
          :border="true"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        >
        <template slot="isReply" slot-scope="scope">
            <span>{{
              scope.row.isReply == 1 ? "已解答" : "未解答"
            }}</span>
          </template>
        </table-page>
      </div>
    </div>
  </div>
</template>
      <script>
import FormQuery from "@/components/form/form-search";
import TablePage from "@/components/table/table-page";
import { Column } from "./tableColumn";
import { getXzqhInfoToCity } from "@/api/landTrustStatistics";
import { getZXInfo } from "@/api/supplyDemand";

const Query = [
  // {
  //   name: "cjType",
  //   label: "地区：",
  //   componentType: "select",
  //   placeholder: "请选择",
  //   width: "160px",
  //   dataOptions: [],
  // },
  {
    name: "isReply",
    label: "状态：",
    componentType: "select",
    placeholder: "请选择",
    width: "160px",
    dataOptions: [
      {
        name: "已解答",
        value: 1,
      },
      {
        name: "未解答",
        value: 0,
      },
    ],
  },
];
export default {
  components: {
    FormQuery,
    TablePage,
  },
  data() {
    return {
      keyForm: 1, //下拉框为请求来的数据时监听会用到
      form: {},
      Query: Query,
      query: {
        current: 1,
        size: 10,
      },
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      columns: Column,
      tableData: [],
      options: [
        { value: 0, label: "已成交" },
        { value: 1, label: "未成交" },
      ],
    };
  },
  created() {
    // this.initRegion();
    this.getList();
  },
  methods: {
    //获取行政区
    async initRegion() {
      let res = await getXzqhInfoToCity();
      let arr = [];
      if (res.status) {
        res.data[0].list.forEach((item) => {
          arr.push({
            name: item.xzqmc,
            value: item.xzqbh,
          });
        });
        if (arr.length) {
          let hasRegion = setInterval(() => {
            this.Query[0].dataOptions = arr;
            this.keyForm++;
            clearInterval(hasRegion);
          }, 100);
        }
      }
    },
    //分页点击事件
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getList();
    },
     //分页数量改变
    handleSizeChange(size) {
      this.currentPage = 1;
      this.pageSize = size;
      this.getList();
    },
    //获取列表
    async getList() {
      let res = await getZXInfo(this.query);
      if (res.code == 0 && res.data.total > 0) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    //条件点击搜索
    search(params) {
      this.query = { ...this.query, ...params };
      this.getList();
    },
    //条件重置
    reset() {
      this.query={
        current: 1,
        size: 10,
      }
      this.getList();
    },
  },
};
</script>
      <style lang="less" scoped>
.form-list {
  .table-search {
    padding: 0 0 0 30px;
    /deep/.el-button {
      padding: 10px 20px !important;
    }
    .tools {
      margin-bottom: 18px;
      margin-top: 6px;
    }
  }
  .table-content {
    padding: 0 30px;
    margin-top: 6px;
  }
}
</style>
